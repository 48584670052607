<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="autoreply-settings" v-else>
      <UiBanner large :title="$t('autoreply.settings.title')" :subtitle="$t('autoreply.settings.subtitle')">
        <template v-slot:actions>
          <v-spacer />
          <AutoReplySettingsLanguageSelector :current-locale="currentLocale" @updateLocale="updateCurrentLocale" />
        </template>
      </UiBanner>
      <UiContainer large with-actions>
        <form class="autoreply-settings__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
          <!-- <section class="autoreply-settings__form__section">
            <UiTitle>
              <span>{{ $t('autoreply.settings.autoReplyStatus.title') }}</span>
              <v-spacer />
              <v-switch v-model="autoReplyStatus" class="tw-p-0 tw-mt-0 tw--mr-2" hide-details />
            </UiTitle>
            <UiSubtitle :subtitle="$t('autoreply.settings.autoReplyStatus.subtitle')" />
          </section> -->

          <section class="autoreply-settings__form__section">
            <UiTitle :title="$t('autoreply.settings.communicationSettings.title')" />
            <UiSubtitle :subtitle="$t('autoreply.settings.communicationSettings.subtitle')" />

            <div class="autoreply-settings__form__section__grid">
              <div class="required-asterisk">
                <UiLabel id="header">
                  <span>{{ $t('autoreply.settings.communicationSettings.label.header') }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="tw-ml-2" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
                    </template>
                    <span v-dompurify-html="$t('autoreply.settings.communicationSettings.tooltip')"></span>
                  </v-tooltip>
                </UiLabel>
                <v-text-field
                  v-model.trim="header"
                  :error-messages="headerErrors"
                  id="header"
                  solo
                  flat
                  outlined
                  :placeholder="$t('autoreply.settings.communicationSettings.placeholder.header')"
                  :counter="$v.header.$params.maxLength.max"
                />
              </div>
              <div class="required-asterisk">
                <UiLabel id="footer">
                  <span>{{ $t('autoreply.settings.communicationSettings.label.footer') }}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="tw-ml-2" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
                    </template>
                    <span v-dompurify-html="$t('autoreply.settings.communicationSettings.tooltip')"></span>
                  </v-tooltip>
                </UiLabel>
                <v-text-field
                  v-model.trim="footer"
                  :error-messages="footerErrors"
                  id="footer"
                  solo
                  flat
                  outlined
                  :placeholder="$t('autoreply.settings.communicationSettings.placeholder.footer')"
                  :counter="$v.footer.$params.maxLength.max"
                />
              </div>
            </div>
          </section>

          <section class="autoreply-settings__form__section autoreply-settings__form__section--small-margin">
            <UiTitle :title="$t('autoreply.settings.additionalContent.title')" />
            <UiSubtitle :subtitle="$t('autoreply.settings.additionalContent.subtitle')" />

            <div class="autoreply-settings__form__section__grid">
              <div>
                <div class="tw-flex tw-items-center">
                  <UiLabel id="promotion" :label="$t('autoreply.settings.additionalContent.label.promotion')" />
                  <v-spacer />
                  <v-switch v-model="promotionStatus" class="tw-p-0 tw-mt-0 tw--mr-2" dense hide-details />
                </div>
                <v-text-field
                  v-model="promotion"
                  id="promotion"
                  :error-messages="promotionErrors"
                  solo
                  flat
                  outlined
                  :placeholder="$t('autoreply.settings.additionalContent.placeholder.promotion')"
                  :disabled="!promotionStatus"
                />
              </div>

              <div v-if="promotionStatus">
                <UiLabel id="grades" :label="$t('autoreply.settings.additionalContent.label.grades')" />
                <v-select
                  v-model="grades"
                  id="grades"
                  solo
                  flat
                  outlined
                  multiple
                  chips
                  deletable-chips
                  clearable
                  :items="computedGrades"
                  :placeholder="$t('autoreply.settings.additionalContent.placeholder.grades')"
                  hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      color="primary"
                      close
                      @click:close="removeGrade(data.item.value)"
                    >
                      {{ data.item.value
                      }}<v-icon right small color="#FFD600">
                        {{ icons.mdiStar }}
                      </v-icon>
                    </v-chip>
                  </template>
                </v-select>
              </div>
              <div v-if="promotionStatus">
                <div class="tw-flex tw-items-center">
                  <UiLabel id="dateFrom" :label="$t('autoreply.settings.additionalContent.label.dateFrom')" />
                </div>
                <v-menu
                  id="dateFrom"
                  ref="dateMenuFrom"
                  v-model="dateMenuFrom"
                  :close-on-content-click="false"
                  :return-value.sync="dateFrom"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFrom"
                      :placeholder="$t('autoreply.settings.additionalContent.placeholder.dateFrom')"
                      solo
                      flat
                      clearable
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="dateFrom = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    :max="dateTo"
                    :locale="$i18n.locale"
                    :first-day-of-week="1"
                    @change="$refs.dateMenuFrom.save(dateFrom)"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="promotionStatus">
                <div class="tw-flex tw-items-center">
                  <UiLabel id="dateTo" :label="$t('autoreply.settings.additionalContent.label.dateTo')" />
                </div>
                <v-menu
                  id="dateTo"
                  ref="dateMenuTo"
                  v-model="dateMenuTo"
                  :close-on-content-click="false"
                  :return-value.sync="dateTo"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateTo"
                      :placeholder="$t('autoreply.settings.additionalContent.placeholder.dateTo')"
                      solo
                      flat
                      clearable
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="dateTo = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    :min="dateFrom"
                    :locale="$i18n.locale"
                    :first-day-of-week="1"
                    @change="$refs.dateMenuTo.save(dateTo)"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </section>
          <UiActions large centered>
            <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
              {{ $t('button.save') }}
            </v-btn>
          </UiActions>
        </form>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { locales } from '@/config/locales.config'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { mdiInformation, mdiStar } from '@mdi/js'
import { formatedDate } from '@/utils/date.util'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiActions from '@/components/UI/Actions.vue'
import AutoReplySettingsLanguageSelector from '@/components/AutoReply/Settings/LanguageSelector.vue'

const INPUT_MAX_COUNT = 200

export default {
  name: 'AutoReplySettings',
  components: {
    UiBanner,
    UiContainer,
    UiLabel,
    UiTitle,
    UiSubtitle,
    UiActions,
    AutoReplySettingsLanguageSelector,
  },
  mixins: [validationMixin],
  data: () => ({
    locales,
    currentLocale: 76,
    saving: false,
    icons: {
      mdiInformation,
      mdiStar,
    },
    dateMenuFrom: false,
    dateMenuTo: false,
    gradesList: [1, 2, 3, 4, 5],
  }),
  async created() {
    this.setUpdating(true)
    this.resetState()
    await this.getSettings()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      settings: state => state.autoreply.settings,
      brandId: state => state.backoffice.currentClient?.brandId,
    }),
    baseHeaderFooter() {
      return {
        brand_id: this.brandId,
        footer: this.footer,
        header: this.header,
        locale_id: this.currentLocale,
      }
    },
    basePromotion() {
      return {
        brand_id: this.brandId,
        comment: this.promotion,
        connotation: 'PO',
        date_from: this.dateFrom,
        date: this.dateTo,
        grades: this.grades,
        locale_id: this.currentLocale,
        status: this.promotionStatus,
        status_date: this.dateFrom || this.dateTo ? 1 : 0,
      }
    },
    customHeaderFooter() {
      return this.settings.header_footer.find(h => h.locale_id === this.currentLocale)
    },
    customPromotion() {
      return this.settings.promotions.find(p => p.locale_id === this.currentLocale)
    },
    defaultHeaderFooter() {
      return this.settings.default_header_footer[this.currentLocale]
    },
    filteredHeaderFooter() {
      return this.settings.header_footer.filter(h => h.locale_id !== this.currentLocale)
    },
    filteredPromotions() {
      return this.settings.promotions.filter(p => p.locale_id !== this.currentLocale)
    },
    computedDateFrom() {
      return this.dateFrom ? formatedDate(this.dateFrom, 'L') : ''
    },
    computedDateTo() {
      return this.dateTo ? formatedDate(this.dateTo, 'L') : ''
    },
    computedGrades() {
      return this.gradesList.map(grade => ({
        text: this.$tc(`autoreply.reviews.filters.grade`, grade, { grade }),
        value: grade,
      }))
    },
    // autoReplyStatus: {
    //   get() {
    //     return this.settings.autoreply_status
    //   },
    //   set(value) {
    //     this.setSettings({
    //       autoreply_status: +value,
    //     })
    //   },
    // },
    header: {
      get() {
        return this.customHeaderFooter?.header ? this.customHeaderFooter.header : this.defaultHeaderFooter.header
      },
      set(value) {
        const headerFooter = this.filteredHeaderFooter
        headerFooter.push({
          ...this.baseHeaderFooter,
          header: value ? value : this.defaultHeaderFooter.header,
        })
        this.setSettings({
          header_footer: headerFooter,
        })
      },
    },
    footer: {
      get() {
        return this.customHeaderFooter?.footer ? this.customHeaderFooter.footer : this.defaultHeaderFooter.footer
      },
      set(value) {
        const headerFooter = this.filteredHeaderFooter
        headerFooter.push({
          ...this.baseHeaderFooter,
          footer: value ? value : this.defaultHeaderFooter.footer,
        })
        this.setSettings({
          header_footer: headerFooter,
        })
      },
    },
    promotion: {
      get() {
        return this.customPromotion?.comment ? this.customPromotion.comment : null
      },
      set(value) {
        const promotions = this.filteredPromotions
        promotions.push({
          ...this.basePromotion,
          comment: value,
        })
        this.setSettings({
          promotions,
        })
      },
    },
    promotionStatus: {
      get() {
        return this.customPromotion?.status ? this.customPromotion.status : 0
      },
      set(value) {
        const promotions = this.filteredPromotions
        promotions.push({
          ...this.basePromotion,
          status: +value,
        })
        this.setSettings({
          promotions,
        })
      },
    },
    dateFrom: {
      get() {
        return this.customPromotion?.date_from ? this.customPromotion.date_from : null
      },
      set(value) {
        const promotions = this.filteredPromotions
        promotions.push({
          ...this.basePromotion,
          date_from: value,
          status_date: value || this.dateTo ? 1 : 0,
        })
        this.setSettings({
          promotions,
        })
      },
    },
    dateTo: {
      get() {
        return this.customPromotion?.date ? this.customPromotion.date : null
      },
      set(value) {
        const promotions = this.filteredPromotions
        promotions.push({
          ...this.basePromotion,
          date: value,
          status_date: value || this.dateFrom ? 1 : 0,
        })
        this.setSettings({
          promotions,
        })
      },
    },
    grades: {
      get() {
        return this.customPromotion?.grades ? this.customPromotion.grades : []
      },
      set(value) {
        const promotions = this.filteredPromotions
        promotions.push({
          ...this.basePromotion,
          grades: value,
        })
        this.setSettings({
          promotions,
        })
      },
    },
    headerErrors() {
      const errors = []
      if (!this.$v.header.$dirty) return errors
      !this.$v.header.required && errors.push(this.$t('error.required'))
      !this.$v.header.maxLength &&
        errors.push(
          this.$t('error.maxLength', {
            minLength: this.$v.header.$params.maxLength.max,
          })
        )
      return errors
    },
    footerErrors() {
      const errors = []
      if (!this.$v.footer.$dirty) return errors
      !this.$v.footer.required && errors.push(this.$t('error.required'))
      !this.$v.footer.maxLength &&
        errors.push(
          this.$t('error.maxLength', {
            minLength: this.$v.footer.$params.maxLength.max,
          })
        )
      return errors
    },
    promotionErrors() {
      const errors = []
      if (!this.$v.promotion.$dirty) return errors
      !this.$v.promotion.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getSettings: 'autoreply/getSettings',
      resetState: 'autoreply/resetState',
      setSettings: 'autoreply/setSettings',
      updateSettings: 'autoreply/updateSettings',
      setAlert: 'backoffice/setAlert',
    }),
    updateCurrentLocale(locale) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.currentLocale = locale
      }
    },
    removeGrade(item) {
      this.grades = this.grades.filter(g => g !== item)
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        try {
          await this.updateSettings()
          this.setAlert({
            color: 'success',
            text: 'Les paramètres des réponses aux avis ont été mis à jour avec succès.',
          })
        } catch {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
        this.saving = false
      }
    },
  },
  validations() {
    return {
      header: {
        required,
        maxLength: maxLength(INPUT_MAX_COUNT),
      },
      footer: {
        required,
        maxLength: maxLength(INPUT_MAX_COUNT),
      },
      promotion: {
        required: requiredIf(() => {
          return this.promotionStatus
        }),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.autoreply-settings {
  &__form {
    &__section {
      @apply tw-mt-12;

      &--small-margin {
        @apply tw-mt-6;
      }

      &:first-child {
        @apply tw-mt-0;
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 sm:tw-gap-x-6 tw-w-full tw-mt-6;
      }
    }
  }
}
</style>
