<template>
  <v-menu offset-y left content-class="language-selector__menu" max-height="350" v-if="locales.length > 1">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :icon="$vuetify.breakpoint.xsOnly" text outlined rounded v-bind="attrs" v-on="on" class="tw--mr-2">
        <v-img :src="require(`@/assets/images/flags/${computedLocale.code}.svg`)" max-width="24" />
        <span v-if="!$vuetify.breakpoint.xsOnly" class="tw-ml-2">
          {{ computedLocale.text }}
        </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(locale, index) in locales"
        :key="index"
        link
        :class="{ 'tw-bg-gray-100': currentLocale === locale.id }"
        @click="updateCurrentLocale(locale.id)"
      >
        <v-list-item-icon class="tw-mr-4">
          <v-img :src="require(`@/assets/images/flags/${locale.code}.svg`)" max-width="24" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title :class="{ 'tw-font-bold': currentLocale === locale.id }">
            {{ locale.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { locales } from '@/config/locales.config'

export default {
  name: 'AutoReplySettingsLanguageSelector',
  props: {
    currentLocale: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    locales,
  }),
  computed: {
    computedLocale() {
      return this.locales.find(l => l.id === this.currentLocale)
    },
  },
  methods: {
    updateCurrentLocale(locale) {
      this.$emit('updateLocale', locale)
    },
  },
}
</script>
